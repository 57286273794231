import React from 'react';

const MoneyWarn = () => (
  <div className="money-advice">
    Warning: Late repayment can cause you serious money problems. For help, go
    to{' '}
    <a href="https://www.moneyadviceservice.org.uk/en" rel="noopener noreferrer" target="_blank">
      moneyadviceservice.org.uk
    </a>
  </div>
);

export default MoneyWarn;
