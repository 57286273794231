import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import { Container, Row, Col } from "reactstrap";
import HeroBanner from "../components/banner";
import ValueExplainer from "../components/valueExplainer";
import MoneyWarn from "../components/moneyWarning";

const standardLayout = ({ data }) => {
  const {
    contentfulPage: {
      title,
      metaDescription,
      metaKeywords,
      hasAHeroBanner,
      mainHeadline,
      hasAValueExplainer,
      mainContentSection: {
        childMarkdownRemark: { html },
      },
    },
  } = data;

  return (
    <Layout>
      <Helmet
        title={title}
        meta={[
          { name: "description", content: metaDescription },
          { name: "keywords", content: metaKeywords || "" },
          {
            name: "google-site-verification",
            content: "Wox6AP2f0F-4vBrd-YNx0M6gR0BUn94KpvbZbwxt6uM",
          },
        ]}
      >
        <html lang="en" />
      </Helmet>

      {hasAHeroBanner && <HeroBanner content={data.contentfulPage} />}
      {hasAValueExplainer && <ValueExplainer />}

      <div className="landingPageContent">
        <Container>
          <Row>
            <Col>
              {!hasAHeroBanner && <h1>{mainHeadline}</h1>}
              <section
                id="mainContent"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <MoneyWarn />
    </Layout>
  );
};

export default standardLayout;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      metaDescription
      title
      mainHeadline
      metaKeywords
      subHeadline
      hasAHeroBanner
      hasAValueExplainer
      defaultLoanAmount
      maxLoanAmount
      minLoanAmount
      mainContentSection {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulPage(filter: { node_locale: { regex: "/en-US/" } }) {
      edges {
        node {
          slug
        }
      }
    }
  }
`;
