import React from 'react';
import { Container, Jumbotron, Row, Col } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import { updateField } from '../redux/actions';
import CoreValueProposition from './coreValueProposition';
import RepresentativeApr from './representativeApr';
import LoanCalculator from './loanCalculator';
import * as f from '../constants/fieldNames';
import Img from 'gatsby-image';

const imageContainerStyles = {
  position: `absolute`,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0.4,
};

let defaultLaSet = false;

const mapStateToProps = ({ data }) => {
  return { data };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateField,
    },
    dispatch
  );
};

const HeroBanner = ({
  content,
  updateField,
  data,
}) => {
  const { defaultLoanAmount, maxLoanAmount, minLoanAmount, mainHeadline, subHeadline } = content;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

  if (defaultLoanAmount && !defaultLaSet) {
    updateField(defaultLoanAmount, f.LOAN_AMOUNT);
    defaultLaSet = true;
  }

  return (
    <StaticQuery
      query={graphql`
        query HeroBannerQuery {
          file(relativePath: { eq: "banner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={contentData => {
        const loanCalcProps = {
          data,
          defaultLoanAmount,
          maxLoanAmount,
          minLoanAmount,
          updateField,
        };
        const headlines = (
          <React.Fragment>
            <h1>{mainHeadline}</h1>
            <h2>{subHeadline}</h2>
          </React.Fragment>
        );
        return (
          <Jumbotron fluid className="heroBanner">
            <Img
              style={imageContainerStyles}
              fluid={contentData.file.childImageSharp.fluid}
            />
            <Container>

              <Row className="mobileBanner">
                <Col>
                  {headlines}
                  <LoanCalculator {...loanCalcProps} />
                  <CoreValueProposition />
                </Col>
              </Row>

              {isDesktopOrLaptop &&
              <React.Fragment>
                <Row className="desktopBanner">
                  <Col xl="8" lg="7">
                    {headlines}
                    <CoreValueProposition/>
                  </Col>
                  <Col xl="4" lg="5">
                    <LoanCalculator {...loanCalcProps} />
                  </Col>
                </Row>
              </React.Fragment>
              }

              <Row>
                <Col>
                  <RepresentativeApr
                    amount={data[f.LOAN_AMOUNT]}
                  />
                </Col>
              </Row>
            </Container>
          </Jumbotron>
        );
      }}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeroBanner);
