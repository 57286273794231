import React, { useEffect } from "react";
import Slider from "rc-slider";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import { Button } from "reactstrap";
import queryString from "query-string";
import { APPLY_CTA } from "../constants/cta";
import * as defaults from "../constants/defaults";
import * as f from "../constants/fieldNames";
import * as ev from "../constants/analyticsEvents";
import checkNumberInput from "../util/checkNumberInput";
import { sanitizeLoanAmount, sanitizeLoanTerm } from "../util/sanitizeLoanData";

function incrementField(
  data,
  fieldName,
  increment,
  updateField,
  modifier,
  max,
  min
) {
  return (e) => {
    let newValue = data[fieldName] + increment;

    if (modifier === "add" && newValue > max) {
      newValue = max;
    }

    if (modifier === "subtract" && newValue < min) {
      newValue = min;
    }

    updateField(newValue, fieldName);
  };
}

const capSliderInputValue = (value, maxVal) => {
  return value > maxVal ? maxVal : value;
};

let updateFromUrlParams = true;

const LoanCalculator = ({
  data,
  updateField,
  maxLoanAmount,
  minLoanAmount,
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.__loanAmount = window.localStorage.setItem(`__loanAmount`, 2000);
      window.__loanAmount = window.localStorage.setItem(`__loanTerm`, 12);
    }
  }, []);
  const queryParams = (typeof window !== "undefined") ? window.location.search : '?la=2000&lt=12';
  const maxLa = maxLoanAmount || defaults.LOAN_AMOUNT_MAX;
  const minLa = minLoanAmount || defaults.LOAN_AMOUNT_MIN;

  if (updateFromUrlParams) {
    const parsedLa = queryString.parse(queryParams).la;
    const parsedLt = queryString.parse(queryParams).lt;

    if (parsedLa) {
      // Allow prepop of Loan amount on landing pages.
      const sanitizedLa = sanitizeLoanAmount(parsedLa);
      updateField(parseInt(sanitizedLa), f.LOAN_AMOUNT);
    }
    if (parsedLt) {
      const sanitizedLt = sanitizeLoanTerm(parsedLt);
      updateField(parseInt(sanitizedLt), f.LOAN_TERM);
    }
    updateFromUrlParams = false;
  }

  return (
    <div className="loanCalculator">
      <div className="loanCalculator__label">
        How much do you want to borrow?
      </div>
      <div className="loanCalculator__input">
        <Button
          outline
          color="primary"
          className="float-left mr-2"
          onClick={incrementField(
            data,
            f.LOAN_AMOUNT,
            -Math.abs(defaults.LOAN_AMOUNT_STEP),
            updateField,
            "subtract",
            maxLa,
            minLa
          )}
        >
          -
        </Button>
        <span className="loanCalculator__label--prefix">£</span>
        <input
          onChange={(event) => {
            const value = capSliderInputValue(event.currentTarget.value, maxLa);
            updateField(value, f.LOAN_AMOUNT);
          }}
          onBlur={(event) => {
            updateField(checkNumberInput(event, maxLa, minLa), f.LOAN_AMOUNT);
          }}
          type="tel"
          maxLength="6"
          className="form-control form-control-lg loanCalculator__input--amount"
          value={data[f.LOAN_AMOUNT]}
        />

        <Button
          outline
          color="primary"
          className="float-right"
          onClick={incrementField(
            data,
            f.LOAN_AMOUNT,
            defaults.LOAN_AMOUNT_STEP,
            updateField,
            "add",
            maxLa,
            minLa
          )}
        >
          +
        </Button>
      </div>
      <Slider
        onChange={(value) => updateField(value, f.LOAN_AMOUNT)}
        value={data[f.LOAN_AMOUNT]}
        max={maxLa}
        min={minLa}
        step={defaults.LOAN_AMOUNT_STEP}
      />
      <div className="loanCalculator__input">
        <Button
          outline
          color="primary"
          className="float-left mr-2"
          onClick={incrementField(
            data,
            f.LOAN_TERM,
            -Math.abs(defaults.LOAN_TERM_STEP),
            updateField,
            "subtract",
            defaults.LOAN_TERM_MAX,
            defaults.LOAN_TERM_MIN
          )}
        >
          -
        </Button>
        <input
          onChange={(event) => {
            const value = capSliderInputValue(
              event.currentTarget.value,
              defaults.LOAN_TERM_MAX
            );
            updateField(value, f.LOAN_TERM);
          }}
          onBlur={(event) => {
            updateField(
              checkNumberInput(
                event,
                defaults.LOAN_TERM_MIN,
                defaults.LOAN_TERM_MAX
              ),
              f.LOAN_TERM
            );
          }}
          type="tel"
          style={{ maxWidth: "6rem" }}
          className="form-control form-control-lg loanCalculator__input--term"
          maxLength="2"
          value={data[f.LOAN_TERM]}
        />
        <span className="loanCalculator__label--suffix">Months</span>
        <Button
          outline
          color="primary"
          className="float-right"
          onClick={incrementField(
            data,
            f.LOAN_TERM,
            defaults.LOAN_TERM_STEP,
            updateField,
            "add",
            defaults.LOAN_TERM_MAX,
            defaults.LOAN_TERM_MIN
          )}
        >
          +
        </Button>
      </div>
      <Slider
        onChange={(value) => updateField(value, f.LOAN_TERM)}
        value={data[f.LOAN_TERM]}
        max={defaults.LOAN_TERM_MAX}
        min={defaults.LOAN_TERM_MIN}
        step={defaults.LOAN_TERM_STEP}
      />

      <a
        className="btn btn-primary btn-lg btn-block"
        href="/apply"
        onClick={() => {
          ReactGA.event({
            category: ev.LANDING_PAGE_CAT,
            action: ev.ACTION_CLICK_TO_FORM,
            label: "Submit loan calculator",
          });
        }}
      >
        {APPLY_CTA}
      </a>
    </div>
  );
};

export default LoanCalculator;
