import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const CoreValueProposition = () => (
  <StaticQuery
    query={graphql`
      query CoreValuePropQuery {
        contentfulCoreValuePropositionBox {
          id
          headline
          pointOne
          pointTwo
          pointThree
          pointFour
        }
      }
    `}
    render={data => {
      const contentData = data.contentfulCoreValuePropositionBox;
      const bullets = [
        contentData.pointOne,
        contentData.pointTwo,
        contentData.pointThree,
        contentData.pointFour,
      ];
      return (
        <section className="coreValueProposition">
          <Row>
            {bullets.map(elem => {
              return (
                <Col key={elem} md="6">
                  <div className="coreValueProposition__bullet">
                    <FontAwesomeIcon
                      style={{
                        width: '1.75rem',
                        height: '1.75rem',
                      }}
                      className="coreValueProposition__check"
                      icon={faCheckCircle}
                    />
                    {elem}
                  </div>
                </Col>
              );
            })}
          </Row>
        </section>
      );
    }}
  />
);

export default CoreValueProposition;
