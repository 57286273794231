export default function checkNumberInput(event, min, max) {
  let num = parseInt(event.currentTarget.value);

  if (num >= max) {
    num = max;
  }
  if (num <= min) {
    num = min;
  }

  num = isNaN(num) ? min : num;
  return num;
}
