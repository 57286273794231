import React from "react";
import { Container, Row, Col } from "reactstrap";
import { StaticQuery, graphql, Link } from "gatsby";
import ReactGA from "react-ga";
import * as ev from "../constants/analyticsEvents";
import { APPLY_CTA } from "../constants/cta";

const ValueExplainer = () => (
  <StaticQuery
    query={graphql`
      query ValueExplainerQuery {
        contentfulValueExplainer {
          id
          headline
          firstSectionTitle
          firstSectionImage {
            file {
              url
            }
          }
          firstSectionContent
          secondSectionTitle
          secondSectionImage {
            file {
              url
            }
          }
          secondSectionContent
          thirdSectionTitle
          thirdSectionImage {
            file {
              url
            }
          }
          thirdSectionContent
        }
      }
    `}
    render={(data) => {
      const explainerData = data.contentfulValueExplainer;
      const contentData = [
        {
          title: explainerData.firstSectionTitle,
          content: explainerData.firstSectionContent,
          imgSrc: explainerData.firstSectionImage.file.url,
        },
        {
          title: explainerData.secondSectionTitle,
          content: explainerData.secondSectionContent,
          imgSrc: explainerData.secondSectionImage.file.url,
        },
        {
          title: explainerData.thirdSectionTitle,
          content: explainerData.thirdSectionContent,
          imgSrc: explainerData.thirdSectionImage.file.url,
        },
      ];

      const Headline = data.contentfulValueExplainer.headline && (
        <Row>
          <Col>
            <h3>{data.contentfulValueExplainer.headline}</h3>
          </Col>
        </Row>
      );

      return (
        <div className="valueExplainer text-center">
          <Container className="mt-5 mb-5">
            <h2 className="valueExplainer__headline">{Headline}</h2>
            <Row>
              {contentData.map((elem) => {
                return (
                  <Col md="4" key={elem.title}>
                    <div className="valueExplainer__wrapper">
                      <img
                        className="valueExplainer__image"
                        src={elem.imgSrc}
                        alt=""
                      />
                    </div>

                    <p className="mb-0 mt-4">
                      <strong>{elem.title}</strong>
                    </p>
                    <p>{elem.content}</p>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col lg={{ size: 4, offset: 4 }} className="mt-3">
                <a
                  className="btn btn-primary btn-lg btn-block"
                  href="/apply"
                  onClick={() => {
                    ReactGA.event({
                      category: ev.VALUE_PROP_CATEGORY,
                      action: ev.ACTION_CLICK_TO_FORM,
                      label: "Click to form",
                    });
                  }}
                >
                  {APPLY_CTA}
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }}
  />
);

export default ValueExplainer;
