import {
  LOAN_AMOUNT_MAX,
  LOAN_AMOUNT_MIN,
  LOAN_AMOUNT,
  LOAN_TERM,
  LOAN_TERM_MAX,
  LOAN_TERM_MIN,
} from '../constants/defaults';

export function sanitizeLoanAmount(la) {
  if (la > LOAN_AMOUNT_MAX) return LOAN_AMOUNT_MAX;
  if (la < LOAN_AMOUNT_MIN) return LOAN_AMOUNT_MIN;
  if (isNaN(la)) return LOAN_AMOUNT;
  return la;
}

export function sanitizeLoanTerm(la) {
  if (la > LOAN_TERM_MAX) return LOAN_TERM_MAX;
  if (la < LOAN_TERM_MIN) return LOAN_TERM_MIN;
  if (isNaN(la)) return LOAN_TERM;
  return la;
}
