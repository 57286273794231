import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const RepresentativeApr = ({amount}) => (
  <StaticQuery
    query={graphql`
      query RepresentativeAprQuery {
        contentfulRepresentativeApr {
          id
          repAprBelow1k {
            id
            childMarkdownRemark {
              html
            }
          }
          repApr {
            id
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => {
      const contentMarkdown = amount < 1000
        ? data.contentfulRepresentativeApr.repAprBelow1k.childMarkdownRemark.html
        : data.contentfulRepresentativeApr.repApr.childMarkdownRemark.html;

      return (
        <div
          className="repApr"
          dangerouslySetInnerHTML={{
            __html: contentMarkdown,
          }}
        />
      );
    }}
  />
);

export default RepresentativeApr;
